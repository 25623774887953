<template>
    <v-scrollable v-if="mappedStories.length > 0"
        :id="blok._uid"
        class="flex snap-x scroll-px-sm flex-nowrap space-x-5 px-sm sm:px-0 md:mx-md md:scroll-px-0 2xl:mx-0">
        <v-scrollable-item v-for="category in mappedStories"
            :key="category.name"
            class="mb-sm w-[130px] shrink-0 snap-start md:w-[160px]">
            <nuxt-link :to="formatSbUrl(category.full_slug)"
                :aria-label="category.name"
                class="space-y-xs hover:underline">
                <div class="size-[130px] overflow-hidden rounded-full bg-[#EFECE9] md:size-[160px]">
                    <nuxt-picture
                        v-if="category.content.menu_image?.filename
                            && category.content.menu_image?.filename.length > 0"
                        :src="category.content.menu_image.filename"
                        provider="storyblok"
                        height="110"
                        width="110"
                        :preload="{ fetchPriority: 'low' }"
                        fit="in"
                        :alt="category.content.meta.title"
                        :aria-label="t('aria.navigate', { page: category.name })"
                        sizes="xs:110px sm:110px md:110px lg:110px xl:110px 2xl:110px"
                        :img-attrs="{
                            // eslint-disable-next-line max-len
                            class:'object-contain m-[25px] w-[80px] h-[80px] md:w-[110px] md:h-[110px] mix-blend-multiply'
                        }" />

                    <nuxt-picture
                        v-else-if="category.content.image?.filename && category.content.image?.filename.length > 0"
                        :src="category.content.image.filename"
                        provider="storyblok"
                        height="160"
                        width="160"
                        :preload="{ fetchPriority: 'low' }"
                        sizes="xs:160px sm:160px md:160px lg:160px xl:160px 2xl:160px"
                        :alt="category.content.meta.title"
                        :aria-label="t('aria.navigate', { page: category.name })"
                        :img-attrs="{
                            class:'object-cover w-[130px] h-[130px] md:w-[160px] md:h-[160px] mix-blend-multiply'
                        }" />
                </div>

                <span class="flex justify-center text-center">
                    {{ category.name }}
                </span>
            </nuxt-link>
        </v-scrollable-item>
    </v-scrollable>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok-js-client';
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-category-menu' }>
}>();

const subCategoryIds = properties.blok.sub_categories.join(',');
const { t } = useI18n();
const { format: formatSbUrl } = useSbUrl();

const subCategoriesData = await useAsyncStoryblokStories(`rooms-${subCategoryIds}`, {
    /* eslint-disable @typescript-eslint/naming-convention */
    starts_with: `${t('storyblok.rooms')}/`,
    by_uuids: subCategoryIds,
    /* eslint-disable @typescript-eslint/naming-convention */
});

const mappedStories = properties.blok.sub_categories
    .map((uuid) => subCategoriesData.find((story) => story.uuid === uuid))
    .filter((story): story is ISbStoryData => !!story);
</script>
